<template>
	<div>
		<div class="kaoqin-wrap">
			<div class="kaoqin-top">
				<div class="left">员工考勤记录表</div>
				<div class="center">({{bumen_info.title}})</div>
				<div class="deta">{{date_start}} - {{date_end}}</div>
			</div>

			<div class="kaoqin-content">
				<div class="kaoqin--list" v-for="(user_item,index) in user_list" :key="index">
					<div class="kaoqin-tipBox-top">
						<div class="text">部门：{{bumen_info.title}}</div>
						<div class="text">{{user_item.name}}</div>
						<div class="text">
							<!-- JG00029 -->
							{{user_item.dayFrom[0] ? user_item.dayFrom[0].deptnumber :'' }}
						</div>
					</div>
					<div class="kaoqin-historyBox">
						<div class="kaoqin-item" v-for="(item,index) in user_item.dayFrom" :key="index">
							{{item.checktime}} {{item.state}}
						</div>
					</div>
					<div class="kaoqin-tipBox-bottom">
						<div class="text">上班签到：31</div>
						<div class="text">下班签退：0</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import dayjs from 'dayjs';
	export default {
		components: {

		},
		data() {
			return {
				current_date: dayjs().format('YYYY-MM-DD'),

				bumen_id: '',
				month: '',
				bumen_info: {},
				title_month: '',
				date_start: '',
				date_end: '',
				//

				//
				bumen_title: '地质资源部',
				bumen_code: 'JG00029',
				//

				user_list: [{
					// downType: '',"downType": 0, //下井状态：0-非下井  1-下井 2-带班下井(零点）3-带班下井（四点） 
					// 4-三班倒早 5-三班倒中  6-三班倒晚

					//状态：
					// 1-正常打卡 
					//  2-旷工（缺卡）  
					//  3-节日 
					//  4-节日加班 
					//  5-补卡  
					// //6-异地打卡
					//  7-事假  
					//  8-病假  
					//  9-工伤 
					//  10-年假（年休） 
					//  11-补卡
				}],
				date_list: 30
			};
		},
		computed: {},
		watch: {

		},
		filters: {
			format_daka_time(str) {
				let time_format = (str || '').substring(11, 16) || ''
				return time_format
			}
		},
		created() {
			this.page_title = this.$route.meta.title
			this.month = this.$route.query.month || '';
			this.bumen_id = this.$route.query.id || '';

			this.set_date_info()
			this.query_bumen_info()
			this.setView()
		},
		mounted() {

		},
		methods: {
			set_date_info() {
				this.month_date = this.$route.query.month
				let day_info = dayjs(this.month_date) //
				//时间的开始
				this.date_start = day_info.startOf('month').format("YYYY-MM-DD")
				//时间的结束
				this.date_end = day_info.endOf('month').format("YYYY-MM-DD")
			},
			query_bumen_info() {
				this.$api("getCategory", {
					id: this.bumen_id
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.bumen_info = res.data;
					}
				});
			},

			setView() {
				console.log(this.forms)
				this.loading = true
				this.$api("kaoqin/getDepartLogs", {
					page: 1,
					limit: 100,
					month: this.month,
					deptId: this.bumen_id,
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						let list = res.data.list;
						this.user_list = list
					} else {
						alertErr(res.msg)
					}
				});
			},

		},
	};
</script>


<style scoped lang="less">
	.kaoqin-wrap {
		background: #fff;
		padding: 43px 26px;
		border: 0.5px solid #E6E6E6;
		border-radius: 8px 8px 8px 8px;

		.kaoqin-content {
			.kaoqin--list {
				margin-bottom: 20px;

				.kaoqin-tipBox-top {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 14px;

					.text {
						font-weight: bold;
						font-size: 14px;

						&:nth-child(2) {
							width: 300px;
							text-align: left;
						}
					}
				}
			}

			.kaoqin-historyBox {
				display: flex;
				flex-wrap: wrap;
				// border: 1px solid #D0D0D0;
				border: 1px solid transparent;
				border-left: 1px solid #D0D0D0;
				border-top: 1px solid #D0D0D0;



				.kaoqin-item {
					width: 25%;
					text-align: center;
					padding: 8px 0;
					font-size: 14px;
					color: #333;
					border: 1px solid #D0D0D0;
					border-top: none;
					border-left: none;

					&:nth-child(-n + 4) {
						border-top: 1px solid #D0D0D0;
					}


					&:nth-child(1) {}
				}
			}
		}


	}

	.kaoqin-top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 40px;

		.left {
			font-size: 18px;
			color: #333;
			font-weight: bold;
		}

		.center,
		.deta {
			font-size: 18px;
			color: #333;
		}

	}

	.kaoqin-tipBox-bottom {
		display: flex;
		align-items: center;
		border: 1px solid #D0D0D0;
		border-top: 0;
		border-top: none;

		.text {
			width: 390px;

			padding: 8px 23px;
			font-size: 14px;
			color: #333;
			font-size: 14px;
			color: #333;
			border-right: 2px solid #D0D0D0;

			&:last-child {
				border-right: 0;
			}
		}
	}
</style>